var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "order-detail",
    },
    [
      _c(
        "el-card",
        { staticClass: "card" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "bill-nav",
                  class: {
                    "nav-audit-icon": _vm.auditOrder,
                    "nav-stop-icon": _vm.stopOrder,
                    "nav-noAudit-icon": _vm.noAuditOrder,
                    "nav-delete-icon": _vm.deleteOrder,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "盘点编号", prop: "billNo" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: true,
                                  placeholder: "盘点编号",
                                },
                                model: {
                                  value: _vm.form.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billNo", $$v)
                                  },
                                  expression: "form.billNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单据日期", prop: "billDate" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "单据日期",
                                  disabled: _vm.disabled,
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.form.billDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billDate", $$v)
                                  },
                                  expression: "form.billDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "盘点名称", prop: "checkName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.disabled,
                                  placeholder: "盘点名称",
                                },
                                model: {
                                  value: _vm.form.checkName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "checkName", $$v)
                                  },
                                  expression: "form.checkName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "盘点仓库", prop: "storeId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "盘点仓库",
                                    disabled: _vm.disabled,
                                    filterable: "",
                                    clearable: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.$refs.form.validateField(
                                        "storeId"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.storeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "storeId", $$v)
                                    },
                                    expression: "form.storeId",
                                  },
                                },
                                _vm._l(_vm.storeData, function (item) {
                                  return _c("el-option", {
                                    key: item.storeId,
                                    attrs: {
                                      label: item.storeName,
                                      value: item.storeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "单据备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.disabled,
                                  type: "textarea",
                                  placeholder: "备注长度介于 1 和 80 字符之间",
                                  maxlength: "80",
                                  autosize: { minRows: 2, maxRows: 4 },
                                },
                                model: {
                                  value: _vm.form.billRemark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billRemark", $$v)
                                  },
                                  expression: "form.billRemark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bill-btn" },
                [
                  _c(
                    "el-row",
                    { staticClass: "mb8", attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                plain: "",
                                icon: "el-icon-download",
                                size: "mini",
                                disabled: _vm.disabled,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm(true)
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                plain: "",
                                size: "mini",
                                disabled: _vm.disabled,
                              },
                              on: { click: _vm.AddBill },
                            },
                            [_vm._v("保存并新增")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                "split-button": "",
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-circle-check",
                                disabled: _vm.showAudit,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.auditBill("审核")
                                },
                              },
                            },
                            [_vm._v(" 审核 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right-btn" },
                    [
                      _c(
                        "el-dropdown",
                        {
                          directives: [
                            {
                              name: "print",
                              rawName: "v-print",
                              value: _vm.PrintObj,
                              expression: "PrintObj",
                            },
                          ],
                          attrs: {
                            "split-button": "",
                            type: "primary",
                            size: "mini",
                            plain: "",
                          },
                        },
                        [
                          _vm._v(" 打印 "),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "打印预览" } },
                                [_vm._v("打印预览")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "打印设置" } },
                                [_vm._v("打印设置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "bill-table" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "dragTable",
                              staticClass: "table",
                              class: { "table-icon": _vm.disabled },
                              attrs: {
                                id: "print",
                                data: _vm.form.billDetailItems,
                                height: "500",
                                "max-height": "500",
                                border: "",
                                "show-summary": "",
                                "summary-method": _vm.getSummaries,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  type: "index",
                                  width: "80",
                                  "class-name": "allowDrag",
                                  align: "center",
                                },
                              }),
                              !_vm.disabled
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "操作",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus operatePush",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.row(
                                                      "push",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-remove operateDel",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.row(
                                                      "del",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      901523703
                                    ),
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品编码",
                                  width: "160",
                                  align: "center",
                                  prop: "goodsNo",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "billDetailItems." +
                                                scope.$index +
                                                ".goodsNo",
                                              rules:
                                                _vm.rules[
                                                  "billDetailItems.goodsNo"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                on: { focus: _vm.onInputFocus },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.keyup(
                                                      "goodsNo",
                                                      $event,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.goodsNo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "goodsNo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.goodsNo",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-more more",
                                                  attrs: { slot: "suffix" },
                                                  on: {
                                                    click: _vm.selectGoods,
                                                  },
                                                  slot: "suffix",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品条码",
                                  width: "160",
                                  align: "center",
                                  prop: "barcode",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品名称",
                                  align: "center",
                                  prop: "goodsName",
                                  "show-overflow-tooltip": "",
                                  width: "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "规格",
                                  align: "center",
                                  prop: "goodsSpec",
                                  "show-overflow-tooltip": "",
                                  width: "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单位",
                                  align: "center",
                                  prop: "unitId",
                                  width: "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "billDetailItems." +
                                                scope.$index +
                                                ".unitId",
                                              rules:
                                                _vm.rules[
                                                  "billDetailItems.unitId"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.checkUnitSalePriceChange(
                                                      $event,
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.unitId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "unitId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.unitId",
                                                },
                                              },
                                              _vm._l(
                                                scope.row.units,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.unitId,
                                                    attrs: {
                                                      label: item.unitName,
                                                      value: item.unitId,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "账面数",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                  width: "120",
                                  prop: "basUnitAccountQty",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "盘点数",
                                  align: "center",
                                  width: "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "billDetailItems." +
                                                scope.$index +
                                                ".unitQty",
                                              rules:
                                                _vm.rules[
                                                  "billDetailItems.unitQty"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              nativeOn: {
                                                keyup: function ($event) {
                                                  return _vm.onkeyupUnitQty(
                                                    scope.row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.unitQty,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "unitQty",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "scope.row.unitQty",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单价(元)",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                  width: "120",
                                  prop: "unitSalePrice",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "金额(元)",
                                  align: "center",
                                  width: "120",
                                  prop: "unitMoney",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.unitMoney)),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "盘盈数量",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                  width: "100",
                                  prop: "basUnitAdjustQty",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "盘盈金额",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                  width: "100",
                                  prop: "basUnitAdjustMoney",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "盘亏数量",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                  width: "100",
                                  prop: "basUnitLoseQty",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "盘亏金额",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                  width: "100",
                                  prop: "basUnitLoseMoney",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "备注",
                                  align: "center",
                                  prop: "remark",
                                  width: "155",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-input", {
                                          attrs: { disabled: _vm.disabled },
                                          model: {
                                            value: scope.row.remark,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "remark", $$v)
                                            },
                                            expression: "scope.row.remark",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("SelectGoods", {
            attrs: { OpenGoods: _vm.OpenGoods, queryCode: _vm.queryCode },
            on: {
              "update:OpenGoods": function ($event) {
                _vm.OpenGoods = $event
              },
              "update:open-goods": function ($event) {
                _vm.OpenGoods = $event
              },
              getGoodS: _vm.getGoodS,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }