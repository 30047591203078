<template>
  <div class="theBody">
    <!-- 左边整体表格 -->
    <div class="right-body">
      <!-- 单据日期 -->
      <div class="bill-nav">
        <el-form ref="form" :model="queryParams" label-width="90px">
          <div class="x-w">
            <el-form-item label="单据日期">
              <el-date-picker
                v-model="datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label-width="20px">
              <el-radio-group v-model="queryParams.filterTime" size="medium">
                <el-radio-button label="today" style="max-width: 70px"
                  >今天</el-radio-button
                >
                <el-radio-button label="yesterday" style="max-width: 70px"
                  >昨天</el-radio-button
                >
                <el-radio-button label="thisWeek" style="max-width: 70px"
                  >本周</el-radio-button
                >
                <el-radio-button label="lastWeek" style="max-width: 70px"
                  >上周</el-radio-button
                >
                <el-radio-button label="thisMonth" style="max-width: 70px"
                  >本月</el-radio-button
                >
                <el-radio-button label="lastMonth" style="max-width: 70px"
                  >上月</el-radio-button
                >
                <el-radio-button label="thisSeason" style="max-width: 70px"
                  >本季</el-radio-button
                >
                <el-radio-button label="lastSeason" style="max-width: 70px"
                  >上季</el-radio-button
                >
                <el-radio-button label="thisYear" style="max-width: 70px"
                  >本年</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label-width="20px">
              <el-button
                type="primary"
                :icon="icon"
                size="mini"
                @click="showHighSearch"
                >高级搜索
              </el-button>
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="getList"
                >搜索
              </el-button>
            </el-form-item>
          </div>
          <div class="HighSearch x-f" v-show="showHigh">
            <el-form-item label="盘点单号">
              <el-input
                class="vipNum"
                v-model="queryParams.billNo"
                placeholder="全部"
              ></el-input>
            </el-form-item>
            <div v-if="isCheck">
              <el-form-item label="盘点名称">
                <el-input
                  class="vipNum"
                  v-model="queryParams.billName"
                  placeholder="全部"
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="isCheck">
              <el-form-item label="盘点仓库">
                <el-select
                  v-model="queryParams.storeId"
                  placeholder="全部"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in storeData"
                    :key="item.storeId"
                    :label="item.storeName"
                    :value="item.storeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-if="isCheckFull">
              <el-form-item label="盘盈仓库">
                <el-select
                  v-model="queryParams.inStoreId"
                  placeholder="全部"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in storeData"
                    :key="item.storeId"
                    :label="item.storeName"
                    :value="item.storeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="盘亏仓库" v-if="isCheckLose">
              <el-select
                v-model="queryParams.outStoreId"
                placeholder="全部"
                filterable
                clearable
              >
                <el-option
                  v-for="item in storeData"
                  :key="item.storeId"
                  :label="item.storeName"
                  :value="item.storeId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单据状态" prop="cardStatus">
              <el-select
                v-model="queryParams.billStatus"
                placeholder="全部"
                filterable
                clearable
              >
                <el-option
                  v-for="dict in dict.type.bill_status"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="marB15 x-f">
        <el-button
          class="marR15"
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          >新增
        </el-button>
        <el-button
          class="marR15"
          split-button
          type="primary"
          size="mini"
          icon="el-icon-circle-check"
          :disabled="multiple"
          @click="auditBill('审核')"
        >
          审核
        </el-button>
        <el-button
          class="marR15"
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="auditBill('删除')"
          >删除
        </el-button>
        <el-button
          class="marR15"
          plain
          icon="el-icon-refresh"
          size="mini"
          @click="test($event)"
          >刷新
        </el-button>
        <el-button
          class="marR15"
          v-show="false"
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleImport"
        >
          导入
        </el-button>
        <el-button
          class="marR15"
          v-show="false"
          type="warning"
          icon="el-icon-upload2"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </div>
      <div>
        <el-table
          v-loading="loading"
          ref="BillTable"
          tooltip-effect="dark"
          style="width: 100%"
          :data="tableData"
          @selection-change="handleSelectionChange"
          border
          max-height="500"
          height="500"
          @row-click="handleRowClick"
        >
          <el-table-column type="selection" width="50" align="center" />
          <el-table-column
            label="序号"
            align="center"
            prop="userId"
            width="80"
            type="index"
          />
          <el-table-column
            show-overflow-tooltip
            label="盘点单号"
            align="center"
            prop="billNo"
            width="155"
          >
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="handleDetail(scope.row)"
                target="_blank"
              >
                {{ scope.row.billNo }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isCheck"
            label="盘点名称"
            align="center"
            prop="checkName"
            width="155"
          />
          <el-table-column
            label="单据状态"
            align="center"
            prop="billStatusName"
          />
          <el-table-column
            label="仓库编号"
            align="center"
            :prop="isStoreNo"
            width="150"
          />
          <el-table-column
            label="仓库名称"
            align="center"
            :prop="isStoreName"
          />
          <el-table-column
            v-if="isLabelDiskMoney"
            :label="labelDiskMoney"
            align="center"
            prop="billMoney"
          />

          <el-table-column label="审核人" align="center" prop="auditBy" />
          <el-table-column
            label="审核日期"
            align="center"
            prop="auditTime"
            width="155"
          />
          <el-table-column
            label="备注"
            align="center"
            prop="billRemark"
            width="150"
          />
          <el-table-column label="制单人" align="center" prop="createBy" />
          <el-table-column
            label="制单日期"
            align="center"
            prop="createTime"
            width="155"
          />
          <el-table-column label="修改人" align="center" prop="updateBy" />
          <el-table-column
            label="修改日期"
            align="center"
            prop="updateTime"
            width="155"
          />
        </el-table>
        <div class="bill-footer">
          <div class="left">
            <span class="select"
              >已选<span class="num">{{ ids.length }}</span
              >条</span
            >
            <span class="total">共{{ total }}条</span>
            <span class="checkAll" v-show="!isCheckAll" @click="selectAll"
              >选择全部</span
            >
            <span class="checkAll" v-show="isCheckAll" @click="selectAll"
              >取消选择</span
            >
          </div>
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
          />
        </div>
      </div>
    </div>

    <!-- 用户导入对话框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px">
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        :headers="upload.headers"
        :action="upload.url + '?updateSupport=' + upload.updateSupport"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <el-checkbox v-model="upload.updateSupport" />
          是否更新已经存在的用户数据
          <el-link type="info" style="font-size: 12px" @click="importTemplate"
            >下载模板
          </el-link>
        </div>
        <div class="el-upload__tip" style="color: red" slot="tip">
          提示：仅允许导入“xls”或“xlsx”格式文件！
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
// 导入模板接口importTemplate
import { importTemplate } from '@/api/system/user' //导入接口
import { allStoreList } from '@/api/system/store' //仓库接口
import { listOrder, updateOrderStatus } from '@/api/shop/bill/check' //门店

export default {
  name: 'shopCheck',
  dicts: ['bill_status'],
  data () {
    return {
      updateStatusObj: {}, //修改单据状态
      isStoreNo: 'storeNo', //表格仓库编号
      isStoreName: 'storeName', //表格仓库名称
      isCheck: false, //盘点名称
      isCheckFull: false, //盘盈仓库
      isCheckLose: false, //盘亏仓库
      isLabelDiskMoney: false, //盘亏、盘盈金额是否显示
      labelDiskMoney: '金额', //表格金额字段
      urlList: undefined, //列表
      urlBillStatus: undefined, //状态
      routerName: undefined, //新增跳转的路由名字
      billType: undefined, //单据类型
      isCheckAll: false, //判断当前是否全选数据
      storeData: [], //仓库数据
      //高级搜索图标
      icon: 'el-icon-caret-bottom',
      //是否显示高级搜索
      showHigh: false,
      datetime: undefined, //单据查询时间
      ids: [], // 选中数组的Id
      billNo: [], //选中数组的编号
      billStatusZeroId: [], //选中数组的未审核的状态0
      billStatusTwoId: [], //选中数组的已审核的状态2
      billStatusThreeId: [], //选中数组的已删除的状态3
      total: 0, // 总条数
      loading: false, //加载框
      multiple: true, //审核、删除的禁用
      tableData: [], //表格
      purAllList: [], //单据表格所有数据
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: '',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: 'Bearer ' + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + '/system/user/importData'
      }
    }
  },
  props: {
    orderType: {
      required: true,
      type: Number
    }
  },
  watch: {
    datetime (newVal) {
      if (newVal) {
        if (this.queryParams.filterTime) {
          this.queryParams.filterTime = undefined
        }
        this.queryParams.beginBillDate = newVal[0]
        this.queryParams.endBillDate = newVal[1]
      }
      if (!newVal) {
        this.queryParams.beginBillDate = undefined
        this.queryParams.endBillDate = undefined
      }
    },
    //侦听时间
    'queryParams.filterTime': {
      handler (newVal) {
        if (newVal) {
          if (this.datetime) {
            this.datetime = undefined
            this.queryParams.beginBillDate = undefined
            this.queryParams.endBillDate = undefined
          }
          //日期快捷触发
          this.getList()
        }
      },
      immediate: true
    },
    //侦听传过来的单据类型
    orderType: {
      handler (newVal) {
        this.changeBillType(newVal)
        this.billType = newVal
      },

      immediate: true
    }
  },
  created () {
    this.getTreeselect()
    this.getList()
  },

  methods: {
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.BillTable.toggleRowSelection(row)
    },
    //接口地址
    changeBillType (billType) {
      if (billType === 140309) {
        //盘点名称
        this.isCheck = true
        //门店盘点
        this.urlList = '/api/system/shop/bill/check/list'
        this.urlBillStatus = '/api/system/shop/bill/check/updateBillStatus'
        this.routerName = 'checkDetail'
      } else if (billType === 140310) {
        //表格
        this.isLabelDiskMoney = true //是否显示金额
        this.labelDiskMoney = '盘盈金额' //表格金额名称
        this.isStoreNo = 'inStoreNo' //表格仓库编号  盘盈
        this.isStoreName = 'inStoreName' //表格仓库编号  盘盈
        this.isCheckFull = true //盘盈仓库
        //门店盘盈
        this.urlList = '/api/system/shop/bill/inventory/surplus/list'
        this.urlBillStatus =
          '/api/system/shop/bill/inventory/surplus/updateBillStatus'
        this.routerName = 'checkFullDetail'
      } else if (billType === 140311) {
        //表格
        this.isLabelDiskMoney = true //是否显示金额
        this.labelDiskMoney = '盘亏金额' //表格金额名称
        this.isStoreNo = 'outStoreNo' //表格仓库编号  盘亏
        this.isStoreName = 'outStoreName' //表格仓库编号  盘亏
        this.isCheckLose = true //盘亏仓库
        //门店盘亏
        this.urlList = '/api/system/shop/bill/inventory/loss/list'
        this.urlBillStatus =
          '/api/system/shop/bill/inventory/loss/updateBillStatus'
        this.routerName = 'checkLoseDetail'
      }
    },
    //点击全选所有数据
    async selectAll () {
      this.isCheckAll = !this.isCheckAll
      if (this.isCheckAll) {
        this.loading = true
        //获取全部采购订单数据
        const res = await listOrder(
          {
            ...this.queryParams,
            pageNum: 1,
            pageSize: this.total
          },
          this.urlList
        )
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.BillTable.toggleRowSelection(row, this.isCheckAll)
          })
        })
        this.loading = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.BillTable.clearSelection()
        })
        this.getList()
      }
    },

    //删除/终止事件
    async auditBill (name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.billStatusZeroId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          if (this.billType === 140310) {
            this.updateStatusObj = {
              billIds: this.billStatusZeroId,
              billStatus: '2',
              billType: this.billType
            }
          } else if (this.billType === 140311) {
            this.updateStatusObj = {
              billIds: this.billStatusZeroId,
              billStatus: '2',
              billType: this.billType
            }
          } else {
            this.updateStatusObj = {
              billIds: this.billStatusZeroId,
              billStatus: '2'
            }
          }
          //发送审核api
          await updateOrderStatus(this.updateStatusObj, this.urlBillStatus)
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === '删除') {
          if (this.billStatusThreeId.length === 0) {
            this.$message.error('勾选单据中没有未审核的单据哦~')
            return
          }
          if (this.billType === 140310) {
            this.updateStatusObj = {
              billIds: this.billStatusZeroId,
              billStatus: '3',
              billType: this.billType
            }
          } else if (this.billType === 140311) {
            this.updateStatusObj = {
              billIds: this.billStatusZeroId,
              billStatus: '3',
              billType: this.billType
            }
          } else {
            this.updateStatusObj = {
              billIds: this.billStatusZeroId,
              billStatus: '3'
            }
          }
          //发送删除api
          await updateOrderStatus(this.updateStatusObj, this.urlBillStatus)
          // 弹出提示
          this.$message.success('删除成功')
        }
        if (this.isCheckAll) {
          this.isCheckAll = false
        }
        await this.getList()
      } catch {}
    },
    //新增单据事件
    handleAdd () {
      this.$router.push({
        name: this.routerName
      })
    },
    //跳转详情
    handleDetail (row) {
      const billId = row.billId
      this.$router.push({
        name: this.routerName,
        query: {
          billId: billId,
          type: 'Update'
        }
      })
    },
    //刷新按钮
    test (e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      // 查询参数
      this.queryParams = {
        pageNum: 1,
        pageSize: 15
      }
      this.getList()
    },
    /** 查询分页列表 */
    async getList () {
      this.loading = true
      const res = await listOrder(this.queryParams, this.urlList)
      this.tableData = res.rows
      this.tableData.forEach(v => {
        if (v.cnBirthday) {
          v.birthday = v.cnBirthday
        }
      })
      this.total = res.total
      this.loading = false
    },

    /** 获取查询数据 */
    async getTreeselect () {
      //获取仓库数据
      const res = await allStoreList()
      this.storeData = res.data
    },

    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.billId) //单据id
      this.billNo = selection.map(item => item.billNo) //单据编号
      //筛选出来未审核的单据状态id0
      this.billStatusZeroId = selection
        .map(item => {
          if (item.billStatus == '0') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来已审核的单据状态id2
      this.billStatusTwoId = selection
        .map(item => {
          if (item.billStatus == '2') {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来未删除的单据状态id3
      this.billStatusThreeId = selection
        .map(item => {
          if ((item.billStatus != '3') & (item.billStatus == '0')) {
            return item.billId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    },
    //是否显示高级搜索
    showHighSearch () {
      this.icon =
        this.icon === 'el-icon-caret-bottom'
          ? 'el-icon-caret-top'
          : 'el-icon-caret-bottom'
      this.showHigh = !this.showHigh
    },
    //遍历树的所有子节点，展开的时候给子节点展开状态赋值true，折叠时候赋值false
    buildData () {
      for (let i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = this.isExpand
      }
    },
    // 筛选节点
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 节点单击事件
    handleNodeClick (data) {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15
      }
      this.queryParams.vipLevelId = data.vipLevelId
      this.getList()
    },
    /** 导入按钮操作 */
    handleImport () {
      this.upload.title = '用户导入'
      this.upload.open = true
    },
    /** 下载模板操作 */
    importTemplate () {
      importTemplate().then(response => {
        this.download(response.msg)
      })
    },
    // 文件上传中处理
    handleFileUploadProgress (event, file, fileList) {
      this.upload.isUploading = true
    },

    // 文件上传成功处理
    handleFileSuccess (response, file, fileList) {
      this.upload.open = false
      this.upload.isUploading = false
      this.$refs.upload.clearFiles()
      this.$alert(response.msg, '导入结果', { dangerouslyUseHTMLString: true })
      this.getList()
    },
    // 提交上传文件
    submitFileForm () {
      this.$refs.upload.submit()
    },
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        '/api/system/vip/base/vipcard/export',
        {
          ...this.queryParams
        },
        `post_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
//整体树
.theBody {
  margin: 20px;

  .right-body {
    display: flex;
    flex-direction: column;

    .bill-nav {
      //表头查询手机号
      .vipNum {
        margin-right: 1rem;
      }
    }

    .bill-btn {
      //整体按钮
      margin-bottom: 20px;
    }

    // 表格
    .bill-footer {
      display: flex;
      justify-content: space-between;

      .left {
        padding: 32px 16px;

        .select {
          .num {
            color: #1890ff;
          }
        }

        .checkAll {
          color: #1890ff;
          cursor: pointer;
        }

        .total {
          margin: 0 10px;
        }
      }
    }
  }
}

::v-deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 355px;
}

.el-radio-group {
  width: 600px;
}

::v-deep .el-radio-button {
  width: 11.11% !important;
}

::v-deep .el-radio-button__inner {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
</style>
