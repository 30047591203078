<template>
  <!-- 单张图片上传 -->
  <div class="singleImageUpload">
    <el-upload
      :action="uploadFile"
      :before-upload="handleBeforeUpload"
      :on-success="handleUploadSuccess"
      list-type="picture-card"
      :class="{ disabled: ruleForm.csAvatar }"
      :limit="1"
      ref="upload"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :file-list="fileList"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <!-- 预览图片 -->
    <el-dialog :visible.sync="dialogVisibleimg" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="加载失败" />
    </el-dialog>
  </div>
</template>

<script>
import { delOss } from "@/api/ossfile"; //阿里云接口
export default {
  name: "SingleImageUpload",
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fileSize: 5,
      uploadFile: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload",
      dialogVisibleimg: false,
      dialogImageUrl: "",
      addimg: [],
      removeimg: [],
      ruleForm: {
        csAvatar: "",
      },
    };
  },
  watch: {
    fileList: {
      handler(val) {
        if (val.length >= 1) this.ruleForm.csAvatar = true;
        else this.ruleForm.csAvatar = false;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          this.$modal.msgError(
            `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件上传大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      this.$modal.loading("正在上传文件，请稍候...");
      this.ruleForm.csAvatar = true;
      return true;
    },
    // 上传成功回调
    handleUploadSuccess(res) {
      this.$emit("getImageUrl", res.data.accessUrl);
      this.$modal.closeLoading();
    },
    // 删除活动展示照片
    async handleRemove(file, fileList) {
      this.ruleForm.csAvatar = false;
      let filePath = file.response?.data?.filePath || file.url;
      await delOss(filePath);
      this.$emit("delImage", filePath);
    },
    // 活动展示照片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleimg = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  ::v-deep .el-upload--picture-card {
    display: none;
  }
}
// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
  transition: all 0s;
}
</style>
