<template>
  <div class="order-detail" v-loading="loading">
    <el-card class="card">
      <!-- 单据日期 -->
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <div
          class="bill-nav"
          :class="{
            'nav-audit-icon': auditOrder,
            'nav-stop-icon': stopOrder,
            'nav-noAudit-icon': noAuditOrder,
            'nav-delete-icon': deleteOrder
          }"
        >
          <el-row>
            <el-col :span="5">
              <el-form-item label="盘点编号" prop="billNo">
                <el-input
                  :disabled="true"
                  v-model="form.billNo"
                  placeholder="盘点编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="单据日期" prop="billDate">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.billDate"
                  type="date"
                  placeholder="单据日期"
                  :disabled="disabled"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="盘点名称" prop="checkName">
                <el-input
                  :disabled="disabled"
                  v-model="form.checkName"
                  placeholder="盘点名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="盘点仓库" prop="storeId">
                <el-select
                  v-model="form.storeId"
                  placeholder="盘点仓库"
                  :disabled="disabled"
                  @blur="$refs.form.validateField('storeId')"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in storeData"
                    :key="item.storeId"
                    :label="item.storeName"
                    :value="item.storeId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="单据备注" prop="remark">
                <el-input
                  :disabled="disabled"
                  v-model="form.billRemark"
                  type="textarea"
                  placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="bill-btn">
          <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
              <el-button
                type="primary"
                plain
                icon="el-icon-download"
                size="mini"
                :disabled="disabled"
                @click="submitForm(true)"
                >保存</el-button
              >
            </el-col>
            <el-col :span="1.5">
              <el-button
                type="primary"
                icon="el-icon-plus"
                plain
                size="mini"
                :disabled="disabled"
                @click="AddBill"
                >保存并新增</el-button
              >
            </el-col>
            <el-col :span="1.5">
              <el-button
                split-button
                type="primary"
                @click="auditBill('审核')"
                size="mini"
                icon="el-icon-circle-check"
                :disabled="showAudit"
              >
                审核
              </el-button>
            </el-col>
          </el-row>
          <div class="right-btn">
            <el-dropdown
              split-button
              type="primary"
              size="mini"
              plain
              v-print="PrintObj"
            >
              打印
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="打印预览">打印预览</el-dropdown-item>
                <el-dropdown-item command="打印设置">打印设置</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div class="bill-table">
          <el-row>
            <el-col :span="24">
              <el-table
                id="print"
                class="table"
                :class="{ 'table-icon': disabled }"
                ref="dragTable"
                :data="form.billDetailItems"
                height="500"
                max-height="500"
                border
                show-summary
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  type="index"
                  width="80"
                  class-name="allowDrag"
                  align="center"
                />
                <el-table-column
                  align="center"
                  label="操作"
                  width="120"
                  v-if="!disabled"
                >
                  <template v-slot="scope">
                    <i
                      @click="row('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i
                      @click="row('del', scope.$index)"
                      class="el-icon-remove operateDel"
                    ></i>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品编码"
                  width="160"
                  align="center"
                  prop="goodsNo"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'billDetailItems.' + scope.$index + '.goodsNo'"
                      :rules="rules[`billDetailItems.goodsNo`]"
                    >
                      <el-input
                        v-model="scope.row.goodsNo"
                        :disabled="disabled"
                        @keyup.enter.native="
                          keyup('goodsNo', $event, scope.$index)
                        "
                        @focus="onInputFocus"
                      >
                        <i
                          slot="suffix"
                          class="el-icon-more more"
                          @click="selectGoods"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品条码"
                  width="160"
                  align="center"
                  prop="barcode"
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  show-overflow-tooltip
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  show-overflow-tooltip
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitId"
                  width="120"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'billDetailItems.' + scope.$index + '.unitId'"
                      :rules="rules[`billDetailItems.unitId`]"
                    >
                      <el-select
                        @change="checkUnitSalePriceChange($event, scope.row)"
                        v-model="scope.row.unitId"
                        :disabled="disabled"
                      >
                        <el-option
                          v-for="item in scope.row.units"
                          :key="item.unitId"
                          :label="item.unitName"
                          :value="item.unitId"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="账面数"
                  align="center"
                  show-overflow-tooltip
                  width="120"
                  prop="basUnitAccountQty"
                >
                </el-table-column>
                <el-table-column label="盘点数" align="center" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'billDetailItems.' + scope.$index + '.unitQty'"
                      :rules="rules[`billDetailItems.unitQty`]"
                    >
                      <el-input
                        @keyup.native="onkeyupUnitQty(scope.row)"
                        v-model.number="scope.row.unitQty"
                        :disabled="disabled"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单价(元)"
                  align="center"
                  show-overflow-tooltip
                  width="120"
                  prop="unitSalePrice"
                >
                </el-table-column>
                <el-table-column
                  label="金额(元)"
                  align="center"
                  width="120"
                  prop="unitMoney"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.unitMoney }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="盘盈数量"
                  align="center"
                  show-overflow-tooltip
                  width="100"
                  prop="basUnitAdjustQty"
                />
                <el-table-column
                  label="盘盈金额"
                  align="center"
                  show-overflow-tooltip
                  width="100"
                  prop="basUnitAdjustMoney"
                />
                <el-table-column
                  label="盘亏数量"
                  align="center"
                  show-overflow-tooltip
                  width="100"
                  prop="basUnitLoseQty"
                />
                <el-table-column
                  label="盘亏金额"
                  align="center"
                  show-overflow-tooltip
                  width="100"
                  prop="basUnitLoseMoney"
                />
                <el-table-column
                  label="备注"
                  align="center"
                  prop="remark"
                  width="155"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.remark"
                      :disabled="disabled"
                    ></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <!-- 选择商品信息框 -->
      <SelectGoods
        :OpenGoods.sync="OpenGoods"
        :queryCode="queryCode"
        @getGoodS="getGoodS"
      ></SelectGoods>
    </el-card>
  </div>
</template>

<script>
import SelectGoods from '@/views/components/selectGoodsUp'
import { listGoods } from '@/api/goods/goods' //商品列表
import {
  addOrder,
  updateOrderStatus,
  getOrderDetail,
  updateOrder,
  seeCheckNumber
} from '@/api/shop/bill/check' //门店盘点
import { getNewDate, getTimeDate } from '@/utils/newDate' //到货时间
import { allStoreList } from '@/api/system/store' //仓库接口
import { getBillNo } from '@/api/codeRule' //单据号
import { checkUnitSalePriceChange } from '@/utils/unitChange' //多级单位切换函数
export default {
  name: 'shopCheckDetail',
  components: { SelectGoods },
  dicts: ['bill_status'],
  data () {
    return {
      billType: undefined, //单据类型
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,

      showAudit: true, //审核
      //打印
      PrintObj: {
        id: 'print'
      },
      //选择商品信息开关
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      //当前表格宽度
      scrollLeft: null,
      //遮罩层
      loading: false,
      //标识已审核
      disabled: false,
      //仓库数据
      storeData: [],

      //表单数据
      form: {
        billDate: '', //单据日期
        billNo: undefined, //单据编号
        billId: undefined, //单据id
        billStatus: '', //单据审核状态
        //单据表格-订单明细
        billDetailItems: [{}]
      },
      //表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入单据编号',
            trigger: ['blur', 'change']
          }
        ],
        billDate: [
          { required: true, message: '请输入制单日期', trigger: 'blur' }
        ],

        storeId: [
          { required: true, message: '请选择盘点仓库', trigger: 'change' }
        ],
        'billDetailItems.goodsNo': [
          {
            required: true,
            message: '商品编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'billDetailItems.unitId': [
          {
            required: true,
            message: '商品单位不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'billDetailItems.unitQty': [
          {
            required: true,
            message: '数量不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d{0,6}(\.\d{0,4})?$/,
            message: '只能输入数值类型且不为空'
          }
        ]
      }
    }
  },
  watch: {
    //侦听路由
    $route: {
      async handler () {
        if (this.$route.query.type === 'Update') {
          if (this.$route.query.billId) {
            this.loading = true
            this.reset()
            //获取仓库详情
            const res = await allStoreList()
            this.storeData = res.data
            //获取单据详情
            const res1 = await getOrderDetail(
              this.$route.query.billId,
              this.urlDetail
            )
            //单据赋值
            this.form = res1.data
            this.loading = false
          }
        } else {
          this.reset()
          this.getBasicData()
          this.disabled = false
          this.auditOrder = false
        }
        //深度监听,同时也可监听到param参数变化
      },
      deep: true,
      immediate: true
    },
    //侦听是否开启按钮
    'form.billStatus': {
      handler (newVal) {
        if (this.form.billStatus === '2') {
          //打开审核图标
          this.auditOrder = true

          //禁用输入按钮、保存按钮
          this.disabled = true
          //打开审核按钮
          this.showAudit = true
        } else if (this.form.billStatus === '3') {
          //打开已删除图标
          this.deleteOrder = true

          //禁用输入按钮、保存按钮
          this.disabled = true
          //禁用审核按钮
          this.showAudit = true
        } else if (this.form.billStatus === '0') {
          //未审核图标
          this.noAuditOrder = true
          //禁用审核按钮
          this.showAudit = false
        } else if (this.form.billStatus === '4') {
          //打开关闭图标
          this.stopOrder = true

          //禁用输入按钮、保存按钮
          this.disabled = true
          //禁用审核按钮
          this.showAudit = true
        } else if (this.form.billStatus === '') {
          //禁用审核按钮
          this.showAudit = true
        }
      },
      immediate: true
    },
    //侦听传过来的单据类型
    orderType: {
      handler (newVal) {
        this.changeBillType(newVal)
        this.billType = newVal
      },
      immediate: true
    }
  },
  props: {
    orderType: {
      required: true,
      type: Number
    }
  },
  methods: {
    checkUnitSalePriceChange,
    //编码获取焦点事件
    onInputFocus (e) {
      if (!this.form.storeId) {
        this.$message({
          message: '请先选择盘点仓库',
          type: 'warning'
        })
        e.srcElement.blur()
        return
      }
    },
    //接口地址
    changeBillType (billType) {
      if (billType === 140309) {
        //门店盘点
        this.urlAdd = '/api/system/shop/bill/check/save'
        this.urlBillStatus = '/api/system/shop/bill/check/updateBillStatus'
        this.urlDetail = '/api/system/shop/bill/check/getDetail'
        this.urlUpdate = '/api/system/shop/bill/check/update'
      }
      if (billType == 170309) {
        // 裱花盘点
        // this.urlAdd = '/api/system/shop/bill/check/save'
        // this.urlBillStatus = '/api/system/shop/bill/check/updateBillStatus'
        // this.urlDetail = '/api/system/shop/bill/check/getDetail'
        // this.urlUpdate = '/api/system/shop/bill/check/update'
        this.urlAdd = '/api/system/decoration/bill/check/save'
        this.urlDetail = '/api/system/decoration/bill/check/getDetail'
        this.urlUpdate = '/api/system/decoration/bill/check/update'
        this.urlBillStatus = '/api/system/decoration/bill/check/updateBillStatus'
      }
      if (billType == 170409) {
        // 裱花盘点
        // this.urlAdd = '/api/system/shop/bill/check/save'
        // this.urlBillStatus = '/api/system/shop/bill/check/updateBillStatus'
        // this.urlDetail = '/api/system/shop/bill/check/getDetail'
        // this.urlUpdate = '/api/system/shop/bill/check/update'
        this.urlAdd = '/api/system/freshly/baked/bill/check/save'
        this.urlDetail = '/api/system/freshly/baked/bill/check/getDetail'
        this.urlUpdate = '/api/system/freshly/baked/bill/check/update'
        this.urlBillStatus = '/api/system/freshly/baked/bill/check/updateBillStatus'
      }
    },
    //数量键盘抬起
    onkeyupUnitQty (row) {
      const unitMoney = row.unitQty * row.unitSalePrice
      this.$set(row, 'unitMoney', unitMoney)
      const num1 = row.unitQty - row.basUnitAccountQty
      if (num1 > 0) {
        //盘盈数量
        row.basUnitAdjustQty = num1
        //盘盈金额
        row.basUnitAdjustMoney = row.basUnitAdjustQty * row.unitSalePrice
        //盘亏数量
        row.basUnitLoseQty = 0
        //盘亏金额
        row.basUnitLoseMoney = 0
      } else if (num1 === 0) {
        //盘盈数量
        row.basUnitAdjustQty = 0
        //盘盈金额
        row.basUnitAdjustMoney = 0
        //盘亏数量
        row.basUnitLoseQty = 0
        //盘亏金额
        row.basUnitLoseMoney = 0
      } else if (num1 < 0) {
        const num2 = row.basUnitAccountQty - row.unitQty
        //盘亏数量
        row.basUnitLoseQty = num2
        //盘亏金额
        row.basUnitLoseMoney = row.basUnitLoseQty * row.unitSalePrice
        //盘盈数量
        row.basUnitAdjustQty = 0
        //盘盈金额
        row.basUnitAdjustMoney = 0
      }
    },

    //表单重置
    reset () {
      //关闭已删除图标
      this.deleteOrder = false
      //关闭审核图标
      this.auditOrder = false
      //关闭未审核图标
      this.noAuditOrder = false
      //关闭关闭图标
      this.stopOrder = false

      //表单数据
      this.form = {
        billDate: '', //单据日期
        billId: undefined, //单据id
        //单据状态
        billStatus: '',
        // //单据编号
        billNo: undefined,
        //单据表格-订单明细
        billDetailItems: [{}]
      }
      this.resetForm('form')
    },
    //初始化页面
    async getBasicData () {
      this.loading = true

      //获取单据日期
      this.form.billDate = getNewDate()
      const time1 = getTimeDate()
      let checkName =
        time1 + ' ' + this.$store.getters.curTenant + ' ' + '盘点单'
      this.$set(this.form, 'checkName', checkName)
      //获取仓库详情
      const res = await allStoreList()
      this.storeData = res.data
      //新增时获取单据编号
      const res2 = await getBillNo(140309)
      this.form.billNo = res2
      this.loading = false
    },
    //保存并新增单据
    async AddBill () {
      try {
        await this.$confirm('是否保存并新增单据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })
        await this.submitForm(false)
        this.reset()
        this.loading = true
        const res2 = await getBillNo(140309)
        this.form.billNo = res2
        this.loading = false
      } catch {}
    },
    //订单明细表格合计
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let name = ''
        if (index === 8) {
          name = 'unitQty'
        } else if (index === 10) {
          name = 'unitMoney'
        } else {
          sums[index] = ''
          return
        }

        const values = data.filter(item => !(item[`${name}`] === undefined))
        const values2 = values.map(i => Number(i[`${name}`]))
        sums[index] = values2.reduce((prev, curr) => {
          return prev + curr
        }, 0)
      })
      return sums
    },

    //选中的商品
    async getGoodS (value) {
      this.form.billDetailItems = this.form.billDetailItems.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )

      const goodsIds = value.map(v => v.goodsId)
      const obj = {
        goodsIds,
        storeId: this.form.storeId
      }
      const res = await seeCheckNumber(obj)
      for (let index = 0; index < value.length; index++) {
        this.$set(value[index], 'basUnitAccountQty', 0)
        this.$set(value[index], 'unitQty', 0)
        value[index].basUnitAccountQty = res.rows[index].stockQty
        value[index].unitQty = res.rows[index].stockQty
      }
      value.forEach(v => {
        this.$set(v, 'unitSalePrice', 0)
        this.$set(v, 'unitMoney', 0)
        v.unitSalePrice = v.salePrice
        v.remark = ''
        v.unitMoney = v.unitQty * v.unitSalePrice
        //盘亏数量
        v.basUnitLoseQty = 0
        //盘亏金额
        v.basUnitLoseMoney = 0
        //盘盈数量
        v.basUnitAdjustQty = 0
        //盘盈金额
        v.basUnitAdjustMoney = 0
      })

      this.form.billDetailItems.push(...value)
    },

    //表格单元格回车事件
    keyup (name, event, index) {
      //判断是否输入编码或条码
      if (this.form.billDetailItems[`${index}`][`${name}`]) {
        listGoods({
          [name]: this.form.billDetailItems[`${index}`][`${name}`]
        }).then(async response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //原始赋值
            const obj = {
              goodsIds: [response.rows[0].goodsId],
              storeId: this.form.storeId
            }
            const res = await seeCheckNumber(obj)
            if (response.rows.length > 0) {
              response.rows[0].remark = ''
              //查询商品价格=表格商品价格
              response.rows[0].unitSalePrice = response.rows[0].salePrice
              //查询账目数赋值给商品
              response.rows[0].basUnitAccountQty = res.rows[0].stockQty
              //账面数=盘点数
              response.rows[0].unitQty = response.rows[0].stockQty
              //计算总金额
              response.rows[0].unitMoney =
                response.rows[0].unitQty * response.rows[0].unitSalePrice
              //盘亏数量
              response.rows[0].basUnitLoseQty = 0
              //盘亏金额
              response.rows[0].basUnitLoseMoney = 0
              //盘盈数量
              response.rows[0].basUnitAdjustQty = 0
              //盘盈金额
              response.rows[0].basUnitAdjustMoney = 0
            }

            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.form.billDetailItems, index, response.rows[0])
          } else {
            this.queryCode = this.form.billDetailItems[`${index}`][`${name}`]

            this.OpenGoods = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        this.OpenGoods = true
      }
    },

    //选择商品方法
    selectGoods () {
      if (!this.form.storeId) {
        this.$message({
          message: '请先选择盘点仓库',
          type: 'warning'
        })
        return
      }
      this.OpenGoods = true
    },
    //表格增加/减少一行方法
    row (name, index) {
      if (name === 'push') {
        this.form.billDetailItems.splice(index + 1, 0, {})
      } else {
        if (this.form.billDetailItems.length <= 1) return
        this.form.billDetailItems.splice(index, 1)
      }
    },
    //审核单据方法
    async auditBill (name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.form.billStatus != '0') {
            this.$message.error('此单据不是未审核的状态哦~')
            return
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 2
          }

          //发送审核api
          const res = await updateOrderStatus(obj, this.urlBillStatus)
          this.form = res.data
          // 弹出提示
          this.$message.success('审核成功')
          //禁用全部输入
          this.disabled = true
          //打开已审核图标
          this.auditOrder = true
        } else if (name === '终止') {
          if (this.form.billStatus != '2') {
            this.$message.error('此单据不是未审核的状态哦~')
            return
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 4
          }
          //发送审核api
          const res = await updateOrderStatus(obj, this.urlBillStatus)
          this.form = res.data
          // 弹出提示
          this.$message.success('单据关闭成功')
          //关闭已审核图标
          this.auditOrder = false
          //关闭未审核图标
          this.noAuditOrder = false
          //打开关闭图标
          this.stopOrder = true
        }
      } catch {}
    },

    /** 提交按钮 */
    async submitForm (isBool) {
      if (
        !(JSON.stringify(this.form.billDetailItems[0]) === '{}') &&
        !(this.form.billDetailItems[0].goodsId === undefined)
      ) {
        this.$refs['form'].validate(async valid => {
          if (valid) {
            if (this.form.billId) {
              const res = await updateOrder(this.form, this.urlUpdate)
              //重新赋值给表单，可以把id赋值
              if (isBool) {
                this.form = res.data
                this.$modal.msgSuccess('修改单据成功')
              } else {
                this.$modal.msgSuccess('保存并新增单据成功')
              }
            } else {
              const res = await addOrder(this.form, this.urlAdd)
              //重新赋值给表单，可以把id赋值
              if (isBool) {
                this.form = res.data
                this.$modal.msgSuccess('新增单据成功')
              } else {
                this.$modal.msgSuccess('保存并修改单据成功')
              }
            }
          }
        })
      } else {
        this.$message.error('单据明细不能为空')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-detail {
  padding: 10px;
  background-color: #f3f3f3;
  .el-card {
    min-height: 87vh;
    padding: 10px;

    //未审核图标
    .nav-noAudit-icon {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/noAudit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }
    //已审核图标
    .nav-audit-icon {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/audit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }
    //已审核图标
    .nav-delete-icon {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/delete.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }
    //已关闭图标
    .nav-stop-icon {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/stop.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }
    //按钮区域
    .bill-btn {
      display: flex;
      justify-content: space-between;
      .noAuditBtn {
        position: absolute;
        z-index: 2022;
        bottom: -30px;
        transform: translateX(70%);
        display: none;
        &.show {
          display: block;
        }
      }
    }

    .bill-table {
      ::v-deep.el-form-item {
        margin-bottom: 0;
      }
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
    }
  }
}
//表格
.table {
  width: 100%;
}
</style>
