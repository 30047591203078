var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "order-detail",
    },
    [
      _c(
        "el-card",
        { staticClass: "card" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "bill-nav x-w",
                  class: {
                    "nav-audit-icon": _vm.auditOrder,
                    "nav-stop-icon": _vm.stopOrder,
                    "nav-noAudit-icon": _vm.noAuditOrder,
                    "nav-delete-icon": _vm.deleteOrder,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据编号", prop: "billNo" } },
                    [
                      _c("el-input", {
                        staticClass: "el-inputInner",
                        attrs: { disabled: true, placeholder: "单据编号" },
                        model: {
                          value: _vm.form.billNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billNo", $$v)
                          },
                          expression: "form.billNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据日期", prop: "billDate" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "el-inputInner",
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "单据日期",
                          disabled: _vm.disabled,
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.billDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billDate", $$v)
                          },
                          expression: "form.billDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.arrivalDate
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "到货日期", prop: "arrivalDate" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "el-inputInner",
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              placeholder: "到货日期",
                              disabled: _vm.disabled,
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.arrivalDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "arrivalDate", $$v)
                              },
                              expression: "form.arrivalDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "x-w" },
                    [
                      _vm.produceOrder
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生产班次",
                                prop: "produceOrderId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "el-inputInner",
                                  attrs: {
                                    placeholder: "全部",
                                    disabled: _vm.disabled,
                                    filterable: "",
                                    clearable: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.$refs.form.validateField(
                                        "produceOrderId"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.produceOrderId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "produceOrderId", $$v)
                                    },
                                    expression: "form.produceOrderId",
                                  },
                                },
                                _vm._l(_vm.employeeData, function (item) {
                                  return _c("el-option", {
                                    key: item.employeeId,
                                    attrs: {
                                      label: item.employeeName,
                                      value: item.employeeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "仓库", prop: "outStoreId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "el-inputInner",
                              attrs: {
                                placeholder: "请选择仓库",
                                disabled: _vm.disabled,
                                filterable: "",
                                clearable: "",
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.$refs.form.validateField(
                                    "outStoreId"
                                  )
                                },
                              },
                              model: {
                                value: _vm.form.outStoreId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "outStoreId", $$v)
                                },
                                expression: "form.outStoreId",
                              },
                            },
                            _vm._l(_vm.storeData, function (item) {
                              return _c("el-option", {
                                key: item.storeId,
                                attrs: {
                                  label: item.storeName,
                                  value: item.storeId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "报废原因",
                            prop: " shop_scrap_type ",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "el-inputInner",
                              attrs: {
                                placeholder: "请选择报废原因",
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.form.scrapTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "scrapTypeId", $$v)
                                },
                                expression: "form.scrapTypeId",
                              },
                            },
                            _vm._l(
                              _vm.busiDicts.shop_scrap_type,
                              function (dict) {
                                return _c("el-option", {
                                  key: dict.dictValue,
                                  attrs: {
                                    label: dict.dictLabel,
                                    value: dict.dictValue,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-w" },
                    [
                      _vm.orderStore
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "订货仓库",
                                prop: "orderStoreId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "el-inputInner",
                                  attrs: {
                                    placeholder: "全部",
                                    disabled: _vm.disabled,
                                    filterable: "",
                                    clearable: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.$refs.form.validateField(
                                        "orderStoreId"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.orderStoreId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "orderStoreId", $$v)
                                    },
                                    expression: "form.orderStoreId",
                                  },
                                },
                                _vm._l(_vm.storeData, function (item) {
                                  return _c("el-option", {
                                    key: item.storeId,
                                    attrs: {
                                      label: item.storeName,
                                      value: item.storeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.outStoreId
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: "调出仓库", prop: "outStoreId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "el-inputInner",
                                  attrs: {
                                    placeholder: "全部",
                                    disabled: _vm.disabled,
                                    filterable: "",
                                    clearable: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.$refs.form.validateField(
                                        "outStoreId"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.form.outStoreId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "outStoreId", $$v)
                                    },
                                    expression: "form.outStoreId",
                                  },
                                },
                                _vm._l(_vm.storeData, function (item) {
                                  return _c("el-option", {
                                    key: item.storeId,
                                    attrs: {
                                      label: item.storeName,
                                      value: item.storeId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据备注", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.disabled,
                              type: "textarea",
                              placeholder: "备注长度介于 1 和 80 字符之间",
                              maxlength: "80",
                              autosize: { minRows: 2, maxRows: 4 },
                            },
                            model: {
                              value: _vm.form.billRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billRemark", $$v)
                              },
                              expression: "form.billRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "bill-btn x-b marB15" }, [
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "marR15",
                        attrs: {
                          type: "primary",
                          plain: "",
                          icon: "el-icon-download",
                          size: "mini",
                          disabled: _vm.disabled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm(true)
                          },
                        },
                      },
                      [_vm._v("保存 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "marR15",
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          plain: "",
                          size: "mini",
                          disabled: _vm.disabled,
                        },
                        on: { click: _vm.AddBill },
                      },
                      [_vm._v("保存并新增 ")]
                    ),
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "marR15",
                        attrs: {
                          "split-button": "",
                          type: "primary",
                          size: "mini",
                          plain: "",
                          icon: "el-icon-circle-check",
                          disabled: _vm.showAudit,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.auditBill("审核")
                          },
                          command: _vm.auditBill,
                        },
                      },
                      [
                        _vm._v(" 审核 "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "反审核" } },
                              [_vm._v("反审核")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          plain: "",
                          icon: "el-icon-switch-button",
                          size: "mini",
                          disabled: _vm.single,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.auditBill("终止")
                          },
                        },
                      },
                      [_vm._v("终止 ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right-btn" },
                  [
                    _c(
                      "el-dropdown",
                      {
                        directives: [
                          {
                            name: "print",
                            rawName: "v-print",
                            value: _vm.PrintObj,
                            expression: "PrintObj",
                          },
                        ],
                        attrs: {
                          "split-button": "",
                          type: "primary",
                          size: "mini",
                          plain: "",
                        },
                      },
                      [
                        _vm._v(" 打印 "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "打印预览" } },
                              [_vm._v("打印预览")]
                            ),
                            _c(
                              "el-dropdown-item",
                              { attrs: { command: "打印设置" } },
                              [_vm._v("打印设置")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "bill-table" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "dragTable",
                      staticClass: "table",
                      class: { "table-icon": _vm.disabled },
                      attrs: {
                        id: "print",
                        data: _vm.form.details,
                        height: "500",
                        "max-height": "500",
                        border: "",
                        "show-summary": "",
                        "summary-method": _vm.getSummaries,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          width: "80",
                          "class-name": "allowDrag",
                          align: "center",
                        },
                      }),
                      !_vm.disabled
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "操作",
                              width: "120",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        on: {
                                          click: function ($event) {
                                            return _vm.row("push", scope.$index)
                                          },
                                        },
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.row("del", scope.$index)
                                          },
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              901523703
                            ),
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          width: "160",
                          align: "center",
                          prop: "goodsNo",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    on: { blur: _vm.watchScroll },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.keyup(
                                          "goodsNo",
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.goodsNo,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "goodsNo", $$v)
                                      },
                                      expression: "scope.row.goodsNo",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-more more",
                                      attrs: { slot: "suffix" },
                                      on: { click: _vm.selectGoods },
                                      slot: "suffix",
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品条码",
                          width: "160",
                          align: "center",
                          prop: "barcode",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          align: "center",
                          prop: "goodsName",
                          "show-overflow-tooltip": "",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          prop: "goodsSpec",
                          "show-overflow-tooltip": "",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          align: "center",
                          prop: "unitName",
                          width: "120",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { disabled: _vm.disabled },
                                    on: {
                                      change: function ($event) {
                                        return _vm.unitSalePriceChange(
                                          $event,
                                          scope.row
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$refs.form.validateField(
                                          "details[" + scope.$index + "].unitId"
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.unitId,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "unitId", $$v)
                                      },
                                      expression: "scope.row.unitId",
                                    },
                                  },
                                  _vm._l(scope.row.units, function (item) {
                                    return _c("el-option", {
                                      key: item.unitId,
                                      attrs: {
                                        label: item.unitName,
                                        value: item.unitId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "数量", align: "center", width: "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "details." + scope.$index + ".unitQty",
                                      rules: _vm.rules["details.unitQty"],
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: _vm.disabled },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.onkeyupUnitQty(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.unitQty,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "unitQty",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "scope.row.unitQty",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单价(元)",
                          align: "center",
                          "show-overflow-tooltip": "",
                          width: "120",
                          prop: "unitPrice",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "金额(元)",
                          align: "center",
                          width: "120",
                          prop: "unitMoney",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.unitMoney)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "备注",
                          align: "center",
                          prop: "remark",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input", {
                                  attrs: { disabled: _vm.disabled },
                                  model: {
                                    value: scope.row.remark,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "remark", $$v)
                                    },
                                    expression: "scope.row.remark",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("SelectGoods", {
            attrs: { OpenGoods: _vm.OpenGoods, queryCode: _vm.queryCode },
            on: {
              "update:OpenGoods": function ($event) {
                _vm.OpenGoods = $event
              },
              "update:open-goods": function ($event) {
                _vm.OpenGoods = $event
              },
              getGoodS: _vm.getGoodS,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }