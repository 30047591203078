var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "singleImageUpload" },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          class: { disabled: _vm.ruleForm.csAvatar },
          attrs: {
            action: _vm.uploadFile,
            "before-upload": _vm.handleBeforeUpload,
            "on-success": _vm.handleUploadSuccess,
            "list-type": "picture-card",
            limit: 1,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.handleRemove,
            "file-list": _vm.fileList,
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisibleimg, "append-to-body": "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleimg = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "加载失败" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }